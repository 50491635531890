import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.loading)?_c('Loading'):_c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('Header')],1)],1),_c(VContainer,{attrs:{"fluid":""}},[_c('Question'),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end"},[(
                _vm.validateProcessQuestions == 'processo' ||
                _vm.validateProcessQuestions == 'ultima'
              )?_c(VBtn,{staticClass:"mx-2",attrs:{"outlined":"","color":"secondary","disabled":_vm.loadingButtonFinish},on:{"click":function($event){return _vm.walkQuestion(false)}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-chevron-left")]),_vm._v(" Voltar ")],1):_vm._e(),(
                _vm.validateProcessQuestions == 'primeira' ||
                _vm.validateProcessQuestions == 'processo'
              )?_c(VBtn,{staticClass:"mx-2",attrs:{"disabled":_vm.question &&
                _vm.question.opcao != undefined &&
                _vm.question.opcao != null &&
                _vm.question.opcao != 'null'
                  ? false
                  : true,"color":"success"},on:{"click":function($event){return _vm.walkQuestion()}}},[_vm._v(" Salvar e continuar "),_c(VIcon,{staticClass:"ml-2"},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.validateProcessQuestions == 'ultima')?_c(VBtn,{staticClass:"mx-2",attrs:{"disabled":_vm.question &&
                _vm.question.opcao != undefined &&
                _vm.question.opcao != null &&
                _vm.question.opcao != 'null'
                  ? false
                  : true,"color":"success","loading":_vm.loadingButtonFinish},on:{"click":function($event){return _vm.completeEvaluation()}}},[_vm._v(" Finalizar "),_c(VIcon,{staticClass:"ml-2"},[_vm._v("mdi-check")])],1):_vm._e()],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }