<template>
  <v-card rounded="0" class="pa-2 pb-4" color="#EEEEEE">
    <p class="text-subtitle-2 black--text">
      Perguntas: {{ countQuestions }}/{{ quantityQuestions }}
    </p>
    <h1 class="black--text font-weight-bold text-h5 ml-4">
      {{
        form && form.tipo == "aa"
          ? keyNames[form.tipo]
          : `Avaliação Sobre ${form.data.nome_colaborador_avaliado}`
      }}
    </h1>
  </v-card>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      keyNames: {
        aa: "Autoavaliação",
        ap: "Avaliação de Pares",
        as: "Avaliação de Subordinados",
        al: "Avaliação de Líder",
      },
    };
  },
  computed: {
    form: {
      get() {
        return this.$store.getters["avdFormularios/getForm"];
      },
    },
    countQuestions: {
      get() {
        return this.$store.getters["avdFormularios/getCountQuestions"];
      },
    },
    quantityQuestions() {
      if (!this.form || !this.form.data.perguntas) return 0;

      return this.form.data.perguntas.length;
    },
  },
};
</script>

<style></style>
