<template>
  <v-row v-if="question">
    <v-col cols="12">
      <p class="black--text mb-0 mt-5 text-h6 font-weight-regular">
        {{ question.descricao }}
      </p>
    </v-col>
    <v-col cols="12">
      <Alternatives />
    </v-col>
  </v-row>
</template>

<script>
import Alternatives from "./Alternatives.vue";

export default {
  name: "Question",
  components: {
    Alternatives,
  },
  computed: {
    question: {
      get() {
        return this.$store.getters["avdFormularios/getQuestion"];
      },
    },
  },
};
</script>

<style></style>
