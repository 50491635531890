<template>
  <section>
    <Loading v-if="loading" />
    <div v-else>
      <v-row>
        <v-col cols="12">
          <Header />
        </v-col>
      </v-row>
      <v-container fluid>
        <Question />
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row align-center justify-end">
              <v-btn
                v-if="
                  validateProcessQuestions == 'processo' ||
                  validateProcessQuestions == 'ultima'
                "
                @click="walkQuestion(false)"
                outlined
                color="secondary"
                class="mx-2"
                :disabled="loadingButtonFinish"
              >
                <v-icon class="mr-2">mdi-chevron-left</v-icon> Voltar
              </v-btn>
              <v-btn
                v-if="
                  validateProcessQuestions == 'primeira' ||
                  validateProcessQuestions == 'processo'
                "
                :disabled="
                  question &&
                  question.opcao != undefined &&
                  question.opcao != null &&
                  question.opcao != 'null'
                    ? false
                    : true
                "
                @click="walkQuestion()"
                color="success"
                class="mx-2"
              >
                Salvar e continuar
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="validateProcessQuestions == 'ultima'"
                :disabled="
                  question &&
                  question.opcao != undefined &&
                  question.opcao != null &&
                  question.opcao != 'null'
                    ? false
                    : true
                "
                @click="completeEvaluation()"
                color="success"
                class="mx-2"
                :loading="loadingButtonFinish"
              >
                Finalizar
                <v-icon class="ml-2">mdi-check</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import Header from "./formComponents/Header.vue";
import Question from "./formComponents/Question.vue";
import Loading from "./Loading.vue";

import secureLS from "secure-ls";

const ls = new secureLS({ isCompression: false, encodingType: "" });

export default {
  name: "Form",
  inject: ["$validator"],
  components: {
    Header,
    Question,
    Loading,
  },
  data() {
    return {
      loading: true,
      loadingButtonFinish: false,
    };
  },
  created() {
    const keyNameFormLocalStorage = this.$route.params
      .uuid_collaborator_avaliable
      ? `${this.$route.params.type_form}-${this.$route.params.uuid_form}-${this.$route.params.uuid_collaborator}-${this.$route.params.uuid_collaborator_avaliable}-form`
      : `${this.$route.params.type_form}-${this.$route.params.uuid_form}-${this.$route.params.uuid_collaborator}-form`;

    const form = ls.get(keyNameFormLocalStorage);

    if (form != null && form != undefined && form !== "")
      return this.setFormEvaluation(form);

    this.reqFormEvaluation();
  },
  methods: {
    walkQuestion(next = true) {
      if (next) {
        this.$validator.validate("avd-form-option.opcao").then((result) => {
          if (result) {
            this.saveQuestion();

            this.countQuestions++;
            this.question = this.form.data.perguntas[this.countQuestions - 1];

            this.form.countQuestions = this.countQuestions;
            this.saveResultInFormLocalStorage();
          }
        });
      } else {
        this.countQuestions--;
        this.question = this.form.data.perguntas[this.countQuestions - 1];
      }
    },
    completeEvaluation() {
      this.saveQuestion();
      this.loadingButtonFinish = true;

      const data = {
        uuid: this.$route.params.uuid_form,
      };

      this.$store
        .dispatch("avdFormulariosColaboradores/finishFormEvaluation", data)
        .then(() => {
          this.countQuestions = 0;
          this.$emit("change-action", "finishEvaluation");
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Ocorreu um erro ao finalizar a sua avaliação. Por favor, tente novamente mais tarde.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingButtonFinish = false;
        });
    },

    setFormEvaluation(form) {
      this.$store.dispatch("avdFormularios/setForm", form);
      this.$store.dispatch(
        "avdFormularios/setQuestion",
        form.data.perguntas[form.countQuestions - 1]
      );
      this.$store.dispatch(
        "avdFormularios/setCountQuestions",
        form.countQuestions
      );
      this.$store.dispatch("escalaItens/setEscalasItens", form.data.escalas);

      this.loading = false;
    },
    reqFormEvaluation() {
      let url = `ext/avd-formularios/get-form-evaluation/${this.$route.params.uuid_form}/${this.$route.params.uuid_collaborator}`;

      if (this.$route.params.type_form != "aa") {
        url += `/${this.$route.params.uuid_collaborator_avaliable}`;
      }
      console.log(url, this.$route.params);
      this.$store
        .dispatch("avdFormularios/getFormEvaluation", { url })
        .finally(() => {
          this.loading = false;
        });
    },
    saveResultInFormLocalStorage() {
      const keyNameFormLocalStorage = this.$route.params
        .uuid_collaborator_avaliable
        ? `${this.$route.params.type_form}-${this.$route.params.uuid_form}-${this.$route.params.uuid_collaborator}-${this.$route.params.uuid_collaborator_avaliable}-form`
        : `${this.$route.params.type_form}-${this.$route.params.uuid_form}-${this.$route.params.uuid_collaborator}-form`;

      ls.set(keyNameFormLocalStorage, this.form);
    },
    saveQuestion() {
      const data = {
        info: {
          uuid_formulario: this.$route.params.uuid_form,
          uuid_colaborador: this.$route.params.uuid_collaborator_avaliable
            ? this.$route.params.uuid_collaborator_avaliable
            : this.$route.params.uuid_collaborator,
          uuid_competencia:
            this.form.data.perguntas[this.countQuestions - 1].uuid_competencia,
          id_comportamento: this.form.data.perguntas[this.countQuestions - 1]
            .id_comportamento
            ? this.form.data.perguntas[this.countQuestions - 1].id_comportamento
            : null,
        },
        data: {
          opcao: this.form.data.perguntas[this.countQuestions - 1].opcao,
        },
      };

      this.$store
        .dispatch("avdFormulariosRespostas/saveQuestion", data)
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao salvar a pergunta. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            cor: "red",
          });
        });
    },
  },
  computed: {
    form: {
      get() {
        return this.$store.getters["avdFormularios/getForm"];
      },
    },
    countQuestions: {
      get() {
        return this.$store.getters["avdFormularios/getCountQuestions"];
      },
      set(value) {
        this.$store.dispatch("avdFormularios/setCountQuestions", value);
      },
    },
    question: {
      get() {
        return this.$store.getters["avdFormularios/getQuestion"];
      },
      set(value) {
        this.$store.dispatch("avdFormularios/setQuestion", value);
      },
    },
    validateProcessQuestions() {
      if (!this.form || !this.form.data.perguntas) return "primeira";

      if (this.countQuestions == 1) return "primeira";
      if (this.countQuestions < this.form.data.perguntas.length)
        return "processo";
      if (this.countQuestions >= this.form.data.perguntas.length)
        return "ultima";
    },
  },
};
</script>

<style></style>
