<template>
  <div class="d-flex flex-column">
    <p class="text-subtitle-1 mb-1">
      Selecione a alternativa que melhor descreve está pergunta:
    </p>
    <v-radio-group
      class="ml-3"
      v-model="question.opcao"
      persistent-hint
      hide-details
      v-validate="'required'"
      data-vv-scope="avd-form-option"
      id="opcao"
      name="opcao"
    >
      <v-radio
        v-for="item in escalasItens"
        :key="item.id"
        :label="item.descricao"
        :value="item.id"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "Alternatives",
  inject: ["$validator"],
  computed: {
    question: {
      get() {
        return this.$store.getters["avdFormularios/getQuestion"];
      },
    },
    escalasItens() {
      const escalasItens = this.$store.getters["escalaItens/getEscalasItens"];

      if (!escalasItens || escalasItens.length == 0) return [];

      const escalasSorteadas = escalasItens.sort(function (a, b) {
        if (a.posicao > b.posicao) {
          return 1;
        }
        if (a.posicao < b.posicao) {
          return -1;
        }

        return 0;
      });

      return escalasSorteadas;
    },
  },
};
</script>

<style></style>
